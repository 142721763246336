import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      'https://cdn.appleqin.com/common/qin-web/web-logo.png?x-oss-process=style/def',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: '#Content1_0',
          children: [{ children: <p>脆甜苹果</p>, name: 'text' }],
        },
        subItem: null,
      },
      {
        name: 'item~k63p2mfw3wh',
        className: 'header3-item',
        children: {
          href: '#Content3_0',
          children: [{ children: <p>特点发现</p>, name: 'text' }],
        },
        subItem: null,
      },
      {
        name: 'item~k63p2odbp9',
        className: 'header3-item',
        children: {
          href: '#Content9_0',
          children: [{ children: <p>成熟过程</p>, name: 'text' }],
        },
        subItem: null,
      },
      {
        name: 'item~k63p2pj9ist',
        className: 'header3-item',
        children: {
          href: '#Feature0_0',
          children: [{ children: <p>媒体服务</p>, name: 'text' }],
        },
        subItem: null,
      },
      {
        name: 'item~k63pa80mm0m',
        className: 'header3-item',
        children: {
          href: '#Feature0_0',
          children: [{ children: <p>联系我们</p>, name: 'text' }],
        },
        subItem: null,
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner3 k629au4phm-editor_css' },
  textWrapper: {
    className: 'banner3-text-wrapper k629asaujur-editor_css',
    children: [
      {
        name: 'nameEn',
        className: 'banner3-name-en',
        children: <p>QIN JING YUAN &amp; APPLE QIN</p>,
      },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '秦泾塬 & 秦苹果',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: <p>果农自有品牌 / 一如既往种植好苹果！</p>,
      },
      {
        name: 'time',
        className: 'banner3-time',
        children: <p>产地 / 陕西·长武</p>,
      },
    ],
  },
};
export const Teams00DataSource = {
  wrapper: { className: 'home-page-wrapper teams0-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page teams0' },
  BannerAnim: {
    className: 'banner-anim',
    children: [
      {
        name: 'elem0',
        className: 'teams0-banner-user-elem',
        titleWrapper: {
          className: 'teams0-content-wrapper klqwnbg0uw-editor_css',
          children: [
            {
              name: 'image~klqwueppmsr',
              className: 'klqwn2e6kh-editor_css',
              children:
                'https://cdn.appleqin.com/common/qin-web/gh_1bd79d2f247e_258.jpg',
            },
          ],
        },
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      ' https://cdn.appleqin.com/common/qin-web/qin-web-apple-fushi.png?x-oss-process=style/def',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: <p>红富士</p> },
  content: {
    className: 'content1-content',
    children: (
      <p>
        果型为扁形和桩型。果面光滑、蜡质多、果粉少、干净无果锈。果皮底色黄绿，果面条红或片红，果肉黄白色，肉质细密，硬度大，果汁多，味香，含糖高，酸甜适度，耐储运。10月中旬成熟，可储存至次年6---7月。红富士苹果果实含可溶性物达17.2%，可滴定酸0.25%，糖酸比47：56，每百克果肉含有VC4.41毫克，而且还含有胡罗卜素、脂肪、蛋白质、Ca、Fe等人体需要的营养物质。
      </p>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      ' https://cdn.appleqin.com/common/qin-web/qin-web-apple-gala.png?x-oss-process=style/def',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: { className: 'content2-title', children: <p>嘎啦(gala)</p> },
  content: {
    className: 'content2-content',
    children: (
      <p>
        果实中等大，短圆锥形，果面金黄色。阳面具浅红晕，有红色断续宽条纹，果型端正美观。果顶有五棱，果梗细长，果皮薄，有光泽。果肉浅黄色，肉质致密、细脆、汁多，味甜微酸，十分适口。品质上乘，不耐贮藏。幼树结果早，坐果率高，丰产稳产，容易管理。<span
        >
          
        </span>
        
      </p>
    ),
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      ' https://cdn.appleqin.com/common/qin-web/qin-web-apple-hongjiangjun.png?x-oss-process=style/def',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: <p>红将军</p> },
  content: {
    className: 'content1-content',
    children: (
      <p>
        质地比红富士略松、甜脆爽口、香气馥郁、皮薄多汁。外形却与中国传统的红富士极为相似。红将军苹果与成熟的红富士相比只是在颜色上稍稍有些差异。在形状、个头和重量上没有太大区别。上市时间早。果实圆形，味甜或略酸，品种繁多，是常见水果，具有丰富营养成分。
      </p>
    ),
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: <p>秦泾塬苹果特点 / FEATURE</p>,
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: <p>一如既往种植好苹果</p>,
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              ' https://cdn.appleqin.com/common/qin-web/qin-web-apple-%E7%89%B9%E7%82%B9-%E7%94%9C.png?x-oss-process=style/def',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: <p>包脆包甜</p> },
          content: {
            className: 'content3-content',
            children: <p>苹果包脆包甜，甜脆爽口、香气馥郁、皮薄多汁。</p>,
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              ' https://cdn.appleqin.com/common/qin-web/qin-web-apple-%E7%89%B9%E7%82%B9-%E8%87%AA.png?x-oss-process=style/def',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: <p>自然成熟人工采摘</p>,
          },
          content: {
            className: 'content3-content',
            children: (
              <p>自然生长成熟，人工采摘，保育袋中长大，减少病、虫、鸟伤害。</p>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://cdn.appleqin.com/common/qin-web/qin-web-apple-%E7%89%B9%E7%82%B9-%E7%BA%A7.png?x-oss-process=style/def',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: <p>分级均匀</p> },
          content: {
            className: 'content3-content',
            children: (
              <p>
                苹果按照其直径分级，分别为：60mm（包含）-65mm(不包含)、65mm（包含）-70mm(不包含)、70mm（包含）-75mm(不包含)<b
                >
                  ...
                </b>
              </p>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              ' https://cdn.appleqin.com/common/qin-web/qin-web-apple-%E7%89%B9%E7%82%B9-24.png?x-oss-process=style/def',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: <p>24小时服务</p> },
          content: {
            className: 'content3-content',
            children: <p>24小时客服售前售后咨询，为您解决回答疑问。</p>,
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              ' https://cdn.appleqin.com/common/qin-web/qin-web-apple-%E7%89%B9%E7%82%B9-%E8%A3%85.png?x-oss-process=style/def',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: <p>包装用心</p> },
          content: {
            className: 'content3-content',
            children: (
              <p>
                每颗苹果用心专业的包装，避免在运输中碰伤压伤，同时拒绝过度包装。
              </p>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              ' https://cdn.appleqin.com/common/qin-web/qin-web-apple-%E7%89%B9%E7%82%B9-%E8%B5%94.png?x-oss-process=style/def',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: <p>坏果烂果包赔</p> },
          content: {
            className: 'content3-content',
            children: (
              <p>收到货24小时内，如有坏果烂果及时联系客服，为您解决。</p>
            ),
          },
        },
      },
    ],
  },
};
export const Content90DataSource = {
  wrapper: { className: 'home-page-wrapper content9-wrapper' },
  page: { className: 'home-page content9' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: <p>成熟过程 / TIMELINE</p>,
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'timeline',
    children: [
      {
        name: 'block0',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          content: { className: 'block-content', children: '' },
          name: { className: 'block-name', children: <p>开花</p> },
          textWrapper: { className: 'text-wrapper' },
          time: { className: 'block-time', children: <p>三月</p> },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/qJnGrvjXPxdKETlVSrbe.svg',
          },
          title: { className: 'block-title', children: <p>开花</p> },
          post: { className: 'block-post', children: <p>三月果树开花了</p> },
          img: {
            className: 'block-img k62b6gn50o-editor_css',
            children:
              ' https://cdn.appleqin.com/common/qin-web/%E8%8B%B9%E6%9E%9C-timeline-01%E5%BC%80%E8%8A%B1.png?x-oss-process=style/def',
          },
        },
      },
      {
        name: 'block1',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          content: {
            className: 'block-content',
            children: (
              <p>
                三月底四月苹果的花谢之后，长出了拇指大小的果子，开始渐渐长大。这个时候多加照料果园。
              </p>
            ),
          },
          name: { className: 'block-name', children: <p>结果</p> },
          textWrapper: { className: 'text-wrapper' },
          time: { className: 'block-time', children: <p>三月</p> },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
          },
          title: { className: 'block-title', children: <p>结果</p> },
          post: { className: 'block-post', children: <p>结出拇指大的果实</p> },
          img: {
            className: 'block-img k62dwlyvnng-editor_css',
            children:
              'https://cdn.appleqin.com/common/qin-web/%E8%8B%B9%E6%9E%9C-timeline-02%E7%BB%93%E6%9E%9C.png?x-oss-process=style/def',
          },
        },
      },
      {
        name: 'block2',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          content: {
            className: 'block-content',
            children: (
              <p>
                给拇指大小的苹果穿上保育袋，保护苹果让它在保育袋里生长。减少了病、虫、鸟害，大大提高了果实品质和商品价值，套袋后能生产出着色良好的果实。
              </p>
            ),
          },
          name: { className: 'block-name', children: <p>套袋</p> },
          textWrapper: { className: 'text-wrapper' },
          time: { className: 'block-time', children: <p>四月底之前</p> },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
          },
          title: { className: 'block-title', children: <p>套袋</p> },
          post: { className: 'block-post', children: <p>给苹果穿上保育袋</p> },
          img: {
            className: 'block-img k62dxrfvcdt-editor_css',
            children:
              ' https://cdn.appleqin.com/common/qin-web/%E8%8B%B9%E6%9E%9C-timeline-03%E5%A5%97%E8%A2%8B.png?x-oss-process=style/def',
          },
        },
      },
      {
        name: 'block3',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          content: {
            className: 'block-content',
            children: (
              <p>
                j经过几个月在保育袋中成长，摘掉保育袋，苹果是白色，为了提高套袋果的优质果率，多生产高档优质果品，要根据果实的着色情况适期、分批采收。在适宜采收期内，采收越晚，着色越好，品质越佳。
              </p>
            ),
          },
          name: { className: 'block-name', children: <p>长大</p> },
          textWrapper: { className: 'text-wrapper' },
          time: { className: 'block-time', children: <p>八 / 九月</p> },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
          },
          title: { className: 'block-title', children: <p>长大</p> },
          post: {
            className: 'block-post',
            children: <p>取下套袋苹果长大了</p>,
          },
          img: {
            className: 'block-img k62dyjc86pq-editor_css',
            children:
              ' https://cdn.appleqin.com/common/qin-web/%E8%8B%B9%E6%9E%9C-timeline-04%E9%95%BF%E5%A4%A7.png?x-oss-process=style/def',
          },
        },
      },
      {
        name: 'block~k62eyzp65jn',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          content: {
            className: 'block-content',
            children: (
              <p>
                秋天收获的季节，天高云淡
                ，秋高气爽，瓜果飘香。八九月份即可采摘，其果肉硬脆多汁、乳黄色、果味清香，酸甜可口，是时令最佳果实。
              </p>
            ),
          },
          name: { className: 'block-name', children: <p>成熟</p> },
          textWrapper: { className: 'text-wrapper' },
          time: { className: 'block-time', children: <p>八 / 九月</p> },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/agOOBdKEIJlQhfeYhHJc.svg',
          },
          title: { className: 'block-title', children: <p>成熟</p> },
          post: {
            className: 'block-post',
            children: <p>太阳公公晒出红红的苹果</p>,
          },
          img: {
            className: 'block-img k62dyjc86pq-editor_css',
            children:
              'https://cdn.appleqin.com/common/qin-web/%E8%8B%B9%E6%9E%9C-timeline-05%E6%88%90%E7%86%9F.png?x-oss-process=style/def',
          },
        },
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: <p>服务 / SERVICE</p> }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon k63nyxg684o-editor_css',
              children:
                'https://cdn.appleqin.com/common/qin-web/service-mapmarking.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <blockquote>地址 / Address</blockquote>,
            },
            { name: 'content', children: <p>陕西省咸阳市长武县相公镇</p> },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon k63o01yejx-editor_css',
              children:
                'https://cdn.appleqin.com/common/qin-web/service-call.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <blockquote>热线 / Hotline</blockquote>,
            },
            {
              name: 'content',
              children: <p>（+86）139-9103-2012 / 130-6161-1369</p>,
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon k63o17kbylp-editor_css',
              children:
                'https://cdn.appleqin.com/common/qin-web/service-email.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <blockquote>邮箱 / Email</blockquote>,
            },
            { name: 'content', children: <p>service@appleqin.com</p> },
          ],
        },
      },
    ],
    gutter: -31,
  },
};
export const Contact00DataSource = {
  wrapper: {
    className: 'home-page-wrapper content10-wrapper k62gwc9xc2f-editor_css',
  },
  Content: {
    className: 'icon-wrapper',
    children: {
      icon: {
        className: 'icon k62gx5eksbs-editor_css',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg',
        name: '主要图标',
      },
      iconShadow: {
        className: 'icon-shadow',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg',
        name: '图标影阴',
      },
      url: { children: 'https://gaode.com/place/B038E01QEQ', name: '跳转地址' },
      title: { children: '果园地址', name: '弹框标题' },
      content: { children: '秦家果园  陕西省长武县相公镇', name: '弹框内容' },
    },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://cdn.appleqin.com/common/qin-web/web-logo.png?x-oss-process=style/def',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: <p>果农自有品牌 | 一如既往种植好苹果！</p>,
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '产品' },
        childWrapper: {
          children: [
            { name: 'link0', href: '#', children: <p>红富士</p> },
            { name: 'link1', href: '#', children: <p>嘎啦（gala）</p> },
            { name: 'link2', href: '#', children: <p>红将军</p> },
            { name: 'link3', href: '#', children: <p>套袋苹果</p> },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于' },
        childWrapper: {
          children: [
            { href: '#', name: 'link0', children: 'FAQ' },
            { href: '#', name: 'link1', children: '联系我们' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: <p>关注</p> },
        childWrapper: {
          children: [
            {
              href: 'https://weibo.com/u/5489882339?is_all=1',
              name: 'link0',
              children: <p>微博（秦泾塬）</p>,
              target: '_blank',
            },
            { href: '#', name: 'link1', children: <p>公众号（秦果园）</p> },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: <span>©2020 秦泾塬 &amp; 秦苹果&nbsp;All Rights Reserved &nbsp;&nbsp; <a href='https://beian.miit.gov.cn'>陕ICP备19006637号</a></span>,
  },
};
